export const LOCATION_UPDATE = 'LOCATION_UPDATE';
export const LOCATION_REMOVE = 'LOCATION_REMOVE';

/**
 * Change a location
 * payload.value: new value to apply
 */
export function locationUpdate(value) {
    return { type: LOCATION_UPDATE, value };
}

export function locationRemove() {
    return { type: LOCATION_REMOVE };
}