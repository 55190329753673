import { withRouter } from 'react-router';
import axios from 'axios';
import i18next from 'i18next';
import routes from '../routing/routes';
import configureStore from '../store/store';
import { UtilApi } from '../utils/api.util';

let authToken = null;
const setToken = (token) => {
    authToken = token;
};

let selectedAgenda = null;
const setAgenda = (agenda) => {
    selectedAgenda = agenda;
}

let selectedGroup = null;
const setGroup = (group) => {
    selectedGroup = group;
}

const state = configureStore().getState();

const client = axios.create({
    baseURL: process.env.REACT_APP_API_HOST.trim() + '/' + process.env.REACT_APP_API_VERSION + '/',
    headers: {
        'Accept': 'application/json,application/text-plain,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8',
        //'Accept': 'application/json,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8',
        'Content-Type': 'application/json, multipart/form-data'
    }
});

const get = (url, params) => {
    return request({
        url: url,
        method: 'GET',
        params: params,
        headers: {}
    });
};

const remove = (url, params) => {
    return request({
        url: url,
        method: 'DELETE',
        params: params,
        headers: {}
    });
};

const post = (url, data, headers = {}) => {
    return request({
        url: url,
        method: 'POST',
        data: data,
        headers: headers
    });
};

const request = (requestOptions) => {
    //cancel && cancel();

    UtilApi.cancelRequest(requestOptions.url);

    let nonCancelUrls = ['client/client/agenda/refresh-agenda'];
    if (nonCancelUrls.indexOf(requestOptions.url) < 0) {
        UtilApi.addCancelToken(requestOptions.url)
    }

    if (!authToken) {
        authToken = state.client ? state.client.access_key : null;
    }

    client.defaults.headers.common['Authorization'] = authToken;
    client.defaults.headers.common['X-Agenda'] = selectedAgenda ? selectedAgenda : (state.agenda ? state.agenda.active.id : null);
    client.defaults.headers.common['X-Group'] = selectedGroup ? selectedGroup : (state.group ? state.group.id : null);

    if (requestOptions['headers']['Content-Type']) {
        client.defaults.headers.common['Content-Type'] = requestOptions['headers']['Content-Type'];
    }
    
    if (requestOptions['data']) {
        requestOptions['data']['lng'] = i18next.language;

        if (!requestOptions['data']['data']['agenda']) {
            requestOptions['data']['data']['agenda'] = selectedAgenda ? selectedAgenda : (state.agenda ? state.agenda.active.id : null);
        }

        if (!requestOptions['data']['data']['group']) {
            requestOptions['data']['data']['group'] = selectedGroup ? selectedGroup : (state.group ? state.group.id : null);
        }
    }

    if (requestOptions['params']) {
        if (!requestOptions['params']['agenda']) {
            requestOptions['params']['agenda'] = selectedAgenda ? selectedAgenda : (state.agenda ? state.agenda.active.id : null);
        }

        if (!requestOptions['params']['group']) {
            requestOptions['params']['group'] = selectedGroup ? selectedGroup : (state.group ? state.group.id : null);
        }
    }

    // requestOptions['signal'] = abortController.signal;

    client.defaults.cancelToken = UtilApi.getCancelToken(requestOptions.url);

    // console.log(requestOptions);

    return client(requestOptions)
        .then((response) => {
            // console.log(response.status, response.statusText, ':', response.data.status, response.data.data);
            return response.data;
        }).catch((error) => {
            if (axios.isCancel(error)) {
                // console.log('Request aborted: ' + error);
                return false;
            } else {
                const { status } = error.response;

                // console.log(status);
                if (status === 401) {
                    // TODO: Show unauthorized message
                    // TODO: Check if token is valid; if not redirect to login
                    window.location = routes.auth.login;
                } else if (status === 500) {
                    // LocalStorageService.store('FlashMessage', {type: 'error', data: 'An error occured'});
                    // window.location = routes.error;
                }
            }
        }).then((response) => {
            UtilApi.removeCancelToken(requestOptions.url);

            return response;
        });
};

const isSuccess = (response) => {
    return response && response.status && response.status === 'success';
}

export const API = withRouter({ get, post, remove, isSuccess, setToken, setAgenda, setGroup });