import { API } from '../api';

const search = (params) => {
    return API.get('client/log/log/search', params)
        .then(
            response => {
                if (response.status === 'success') {
                    return response.data.logs;
                }

                throw new Error('Something went wrong');
            });
};

const log = (data) => {
    return API.post('client/log/log/error-log', { data: data });
};

export const ApiLogService = {
    search,
    log
};