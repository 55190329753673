import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { reactI18nextModule } from 'react-i18next';
import moment from 'moment';
import 'moment/min/locales';

const FALLBACK_LANGUAGE = 'nl';

i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(reactI18nextModule)
    .init({
        interpolation: {
            format: (value, format, lng) => {
                if (value instanceof moment) {
                    return value.format(format);
                }
            }
        },
        lng: FALLBACK_LANGUAGE,
        fallbackLng: FALLBACK_LANGUAGE,
        backend: {
            loadPath: 'locales/{{lng}}/{{ns}}.json',
        },
        // have a common namespace used around the full app
        ns: [//alfabetisch!
            'agenda.appointment',
            'agenda.conflict',
            'agenda.dayappointment',
            'agenda.group',
            'agenda.history',
            'agenda.myagenda',
            'agenda.searchfreeslot',
            'agenda.statistics',
            'authentication',
            'component.form',
            'common',
            'communication.mail',
            'communication.sms',
            'datatables',
            'dashboard',
            'discount',
            'error',
            'giftvoucher',
            'header.communication',
            'language',
            'modal.appointmentstatuslog',
            'modal.group',
            'contacts.contact',
            'profile.agenda',
            'profile.mailbox',
            'profile.profile',
            'settings.appointmentstatus',
            'settings.block',
            'settings.category',
            'settings.categoryform',
            'settings.exception',
            'settings.holiday',
            'settings.links',
            'settings.profile',
            'settings.subcategory',
            'settings.shop',
            'settings.workload',
            'sidebar',
            'sidebar.shop',
            'tools.popup',
            'tools.reminder',
            'tools.task',
            'shop.allergens',
            'shop.combideal',
            'shop.product',
            'shop.order',
            'shop.order.detail'
        ],
        keySeparator: '/',
        defaultNS: 'common',
        debug: false,
        react: {
            wait: true
        },
        preload: ['nl', 'fr']
    });

i18n.on('loaded', () => {
    moment.locale(FALLBACK_LANGUAGE);
});

i18n.on('languageChanged', (lng) => {
    moment.locale(lng);
});

export default i18n;