import routes from "../routing/routes";
import { reverse } from "named-urls";

const MenuSetting = [
    {
        name: 'Terug naar agenda',
        icon: 'fa fa-arrow-left',
        translate: 'sidebar:settings:agenda-back',
        path: reverse(routes.agenda.myagenda),
    },
    {
        name: 'Instellingen',
        icon: 'fa fa-cog',
        translate: 'sidebar:settings:main',
        submenu: [{
            name: 'forms',
            translate: 'sidebar:settings:categoryforms',
            path: routes.agenda.categoryforms.overview
        },
        {
            name: 'categories',
            translate: 'sidebar:settings:categories',
            path: routes.agenda.categories.overview
        },
        {
            name: 'profiles',
            translate: 'sidebar:settings:profiles',
            path: routes.agenda.profiles.overview
        },
        {
            name: 'subcategories',
            translate: 'sidebar:settings:subcategories',
            path: routes.agenda.subcategories.overview
        },
        {
            name: 'appointmentstatuses',
            translate: 'sidebar:settings:appointmentstatuses',
            path: routes.agenda.appointmentstatuses.overview
        },
        {
            name: 'holidays',
            translate: 'sidebar:settings:holidays',
            path: reverse(routes.agenda.holidays.overview)
        },
        {
            name: 'exceptions',
            translate: 'sidebar:settings:exceptions',
            path: routes.agenda.exceptions.overview
        },
        {
            name: 'blockings',
            translate: 'sidebar:settings:blockings',
            path: routes.agenda.blockings.overview
        }]
    },
    {
        name: 'Groep instellingen',
        icon: 'fa fa-users-cog',
        translate: 'sidebar:groupsettings:main',
        submenu: [/*{
            name: 'contact',
            translate: 'sidebar:groupsettings:contact',
            path: routes.agenda.settings.group.contact
        },*/
        {
            name: 'group',
            translate: 'sidebar:groupsettings:agenda',
            path: routes.agenda.settings.group.agenda
        },
        {
            name: 'groupsort',
            translate: 'sidebar:groupsettings:sort',
            path: routes.agenda.settings.group.sort
        }]
    },
    {
        name: 'Persoonlijke instellingen',
        icon: 'fa fa-user-cog',
        translate: 'sidebar:personalsettings:main',
        submenu: [{
            name: 'profile',
            translate: 'sidebar:personalsettings:account',
            path: routes.agenda.settings.account.profile
        },
        {
            name: 'personal',
            translate: 'sidebar:personalsettings:mypreferences',
            path: routes.agenda.settings.personal
        },
        {
            name: 'agenda',
            translate: 'sidebar:personalsettings:agendapreferences',
            path: routes.agenda.settings.agenda
        },
        {
            name: 'links',
            translate: 'sidebar:personalsettings:links',
            path: routes.agenda.settings.links
        }]
    }];

export default MenuSetting;