import React from 'react';
import { Badge } from 'reactstrap';
import { t } from 'i18next';
import BadgeToolTip from '../components/badges/BadgeTooltip';

const buildOrderStatusBadge = (item, small = false) => {
    let color = '';
    switch (item.orderStatus) {
        case 1:
        case 4:
            color = 'info';
            break;
        case 2:
        case 3:
            color = 'success';
            break;
        case 5:
            color = 'default';
            break;
        case 6:
            color = 'inverse';
            break;
        default:
            color = 'danger';
            break;
    }

    if (small) {
        return <BadgeToolTip pill id={'badge-order-status-' + item.id} color={color}>{t('shop.order.detail:order-statuses:' + item.orderStatus)}</BadgeToolTip>;
    }

    return <Badge pill color={color}>{!small ? t('shop.order.detail:order-statuses:' + item.orderStatus) : ''}</Badge>;
}

const buildPaymentStatusBadge = (item, small = false) => {
    let color = '';
    switch (item.paymentStatus) {
        case 1:
        case 2:
            color = 'warning';
            break;
        case 3:
            color = 'success';
            break;
        case 4:
            color = 'gray-light';
            break;
        case 5:
            color = 'info';
            break;
        default:
            color = 'danger';
            break;
    }

    if (small) {
        return <BadgeToolTip pill id={'badge-payment-status-' + item.id} color={color}>{t('shop.order.detail:payment-statuses:' + item.paymentStatus)}</BadgeToolTip>;
    }

    return <Badge pill color={color}>{!small ? t('shop.order.detail:payment-statuses:' + item.paymentStatus) : ''}</Badge>;
}

const buildInternalOrderLink = (group, locale) => {
    let { website, webservice } = group;
    
    if (!website ||!webservice) {
         return null;
    }

    website = website.replace(/\/+$/g, '');

    return website + '/' + locale + '/shop/internal-order/' + webservice;
}

export const UtilOrder = {
    buildOrderStatusBadge,
    buildPaymentStatusBadge,
    buildInternalOrderLink
};