import React from 'react';
import { t } from 'i18next';
import { ButtonDropdown, DropdownMenu, DropdownToggle, DropdownItem } from 'reactstrap';
import { bindActionCreators } from 'redux';
import * as actions from '../store/actions';
import { connect } from 'react-redux';
import routes from '../routing/routes';
import { reverse } from 'named-urls';

class LocationSidebar extends React.Component {
    constructor(props) {
        super(props);

        if (props.group.locations && props.group.locations.length === 1) {
            props.actions.locationUpdate(props.group.locations[0]);
        }

        this.state = {
            ddOpen: false
        };
    }

    changeLocation = (item) => {
        this.props.actions.locationUpdate(item);

        //reload
        let location = this.props.location.pathname;

        if (location.startsWith(reverse(routes.agenda.myagenda, { agenda: this.props.agenda.id }))) {
            this.props.history.push(location);
        } else {
            this.props.history.push(this.props.location.pathname);
        }
    }

    toggle = () => this.setState({
        ddOpen: !this.state.ddOpen
    })

    render() {
        const { group, selectedLocation } = this.props;
        const btnColor = this.props.group ? 'default' : 'danger';

        if (!group.locations || group.locations.length <= 0) {
            return null;
        }

        let multipleLocations = group.locations.length > 1;

        return (
            <ButtonDropdown color={btnColor} isOpen={this.state.ddOpen} toggle={this.toggle} className="btn-block mt-2">
                <DropdownToggle color={btnColor} size="sm" caret>
                    {selectedLocation ? selectedLocation.title : t('common:all.locations')}
                </DropdownToggle>
                <DropdownMenu>
                    {multipleLocations && (
                        <DropdownItem onClick={() => this.changeLocation(null)} active={!selectedLocation ? true : false}>
                            {t('common:all.locations')}
                        </DropdownItem>
                    )}
                    {group.locations.map((item, i) => {
                        return (
                            <DropdownItem key={i} onClick={() => this.changeLocation(item)} active={selectedLocation && item.id === selectedLocation.id ? true : false}>
                                {item.title}
                            </DropdownItem>
                        )
                    })}
                </DropdownMenu>
            </ButtonDropdown>
        );
    }
}

const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) })

export default connect(null, mapDispatchToProps)(LocationSidebar);