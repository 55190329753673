import { API } from '../api';

const getByAgenda = () => {
    return API.get('client/group/group/get-by-agenda')
        .then((response) => {
            if (API.isSuccess(response)) {
                return response.data.groups;
            }

            throw response.data;
        })
};

const getActiveGroupAgenda = () => {
    return API.get('client/group/group/get-active-group')
        .then((response) => {
            if (API.isSuccess(response)) {
                return response.data.group;
            }

            return {};
        });
};

const updateAgendaSort = (data) => {
    return API.post('client/group/group/update-sort', data)
        .then((response) => {
            if (API.isSuccess(response)) {
                return response.data;
            }

            return false;
        });
};

const syncCashDesk = (groupId) => {
    return API.post('client/group/group/sync-cash-desk', { data: { group: groupId } })
        .then((response) => {
            if (API.isSuccess(response)) {
                return true;
            }

            return false;
        });
};

export const ApiGroupService = {
    getByAgenda,
    getActiveGroupAgenda,
    updateAgendaSort,
    syncCashDesk
};