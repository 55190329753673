import { include } from 'named-urls';

export default {
    // Common routes
    home: '/',
    dashboard: '/',

    notfound: '/404-not-found',
    error: '/500-error',

    auth: {
        login: '/login',
        forgotpassword: '/forgot-password',
        resetpasswordcode: '/reset-password/:code',
        resetpassword: '/reset-password'
    },

    contacts: include('/contacts', {
        overview: '',
        add: 'add',
        edit: 'edit/:id',
        remove: 'remove/:id',
        appointments: 'appointments/:id/:repeated?',
        mail: 'mail/:id',
        confirm: 'confirm'
    }),

    // agenda routes
    agenda: include('/agenda', {
        myagenda: 'agenda/:agenda?/:date?',
        group: 'groupoverview/:date?',

        agenda: {
            history: 'history/:date?/:start?/:end?',
            searchfreeslot: 'searchfreeslot',
            conflicts: 'conflicts',

            printWeek: 'agenda/print/week/:date/:agenda?',
            printDay: 'agenda/print/day/:date/:agenda?',

            appointmentCopy: 'agenda/copy/:appointment/:date/:agenda?',
            appointmentMove: 'agenda/move/:appointment/:date/:agenda?',

            appointmentMoveAgenda: 'groupoverview/move/:agenda/:appointment/:date',
            groupPrint: 'groupoverview/print/:date'
        },

        appointments: include('appointments', {
            overview: '',
            new: 'new/:agenda/:start?/:end?',
            add: 'add/:agenda/:start?/:end?/:category?/:subcategory?',
            edit: 'edit/:agenda/:id',
            remove: 'remove/:id',
            print: 'print/:id',
            search: 'search/:searchStr/:agenda?',
            statistics: include('statistics', {
                overview: ''
            })
        }),

        dayappointments: include('dayappointments', {
            overview: '',
            add: 'add/:agenda?/:date?',
            edit: 'edit/:id',
            remove: 'remove/:id'
        }),

        appointmentstatuses: include('settings/appointmentstatuses', {
            overview: '',
            add: 'add',
            edit: 'edit/:id',
            remove: 'remove/:id'
        }),

        holidays: include('settings/holidays', {
            overview: ':type?',
            add: 'add',
            edit: 'edit/:id',
            remove: 'remove/:id',
            holidays: 'holidays'
        }),

        profiles: include('settings/profiles', {
            overview: '',
            add: 'add',
            edit: 'edit/:id',
            compose: 'compose/:id/:wizard?',
            priority: 'priority/:id',
            remove: 'remove/:id',
            periods: include('periods/:scheduleId', {
                overview: '',
                add: 'add/:wizard?',
                edit: 'edit/:id',
                remove: 'remove/:id'
            })
        }),

        exceptions: include('settings/exceptions', {
            overview: '',
            add: 'add/:date?/:slot?/:agenda?',
            remove: 'remove/:id'
        }),

        blockings: include('settings/blockings', {
            overview: '',
            add: 'add',
            edit: 'edit/:id',
            remove: 'remove/:id'
        }),

        categories: include('settings/categories', {
            overview: '',
            add: 'add',
            edit: 'edit/:id',
            remove: 'remove/:id'
        }),

        subcategories: include('settings/subcategories', {
            overview: '',
            add: 'add',
            edit: 'edit/:id',
            remove: 'remove/:id'
        }),

        categoryforms: include('settings/forms', {
            overview: '',
            add: 'add',
            edit: 'edit/:id',
            remove: 'remove/:id',

            fields: include(':form/field', {
                overview: '',
                add: 'add',
                edit: 'edit/:id',
                remove: 'remove/:id',
            })
        }),

        /* TOOLS */
        popups: include('popups', {
            overview: '',
            add: 'add/:agenda?',
            edit: 'edit/:id',
            remove: 'remove/:id'
        }),

        mailbox: include('mailbox', {
            inbox: ':message?',
            compose: 'compose/:message?/:all?'
        }),

        settings: include('settings', {
            group: include('group', {
                contact: 'contact',
                sort: 'sort',
                agenda: 'agenda'
            }),
            personal: 'personal',
            agenda: 'agenda',
            links: 'links',
            account: include('account', {
                profile: 'profile'
            })
        }),

        communication: include('communication', {
            mail: include('mail', {
                compose: '',
                templates: include('templates', {
                    overview: '',
                    add: 'add',
                    edit: 'edit/:id',
                    remove: 'remove/:id',

                    timings: include(':template/timings', {
                        overview: '',
                        add: 'add',
                        edit: 'edit/:id',
                        remove: 'remove/:id'
                    })
                })
            }),
            sms: include('sms', {
                compose: '',
                numbers: 'numbers',
                templates: include('templates', {
                    overview: '',
                    add: 'add',
                    edit: 'edit/:id',
                    remove: 'remove/:id',

                    timings: include(':template/timings', {
                        overview: '',
                        add: 'add',
                        edit: 'edit/:id',
                        remove: 'remove/:id'
                    })
                })
            }),
        }),
    }),
    // Order routes
    shop: include('/shop', {
        allergens: include('allergens', {
            overview: '',
            add: 'add',
            edit: 'edit/:id',
            remove: 'remove/:id',
        }),
        combideals: include('combi-deals', {
            overview: '',
            add: 'add',
            edit: 'edit/:id',
            remove: 'remove/:id',
            settings: 'settings'
        }),
        products: include('products', {
            overview: '',
            add: 'add/:category?',
            edit: 'edit/:id',
            remove: 'remove/:id',
            category: 'category/:id',

            groups: include('groups', {
                overview: '',
                add: 'add',
                edit: 'edit/:id',
                remove: 'remove/:id',
                activate: 'activate/:id',

                options: include(':group/options', {
                    overview: '',
                    add: 'add',
                    edit: 'edit/:id',
                    remove: 'remove/:id',
                    activate: 'activate/:id'
                })
            }),
            categories: include('categories', {
                overview: '',
                add: 'add',
                edit: 'edit/:id',
                remove: 'remove/:id',
                activate: 'activate/:id',
                products: ':id'
            })
        }),

        orders: include('orders', {
            overview: '',
            overviewPerDay: 'per-day/:date?',
            overviewProductTotalsPerDay: 'product-totals-per-day',
            detail: 'detail/:id',
            edit: 'edit/:id',
            export: 'export'
        }),

        settings: include('settings', {
            group: 'group',
            location: 'location',
            workload: include('workload', {
                overview: '',
                add: 'add',
                edit: 'edit/:id',
                remove: 'remove/:id'
            }),
            workloadplanner: include('workloadplanner', {
                overview: '',
                add: 'add',
                edit: 'edit/:id',
                remove: 'remove/:id',
                compose: 'compose/:id',
                periods: include('periods/:workloadplannerId', {
                    overview: '',
                    add: 'add/:wizard?',
                    edit: 'edit/:id',
                    remove: 'remove/:id'
                }),
            }),
            links: 'links'
        })
    }),

    discounts: include('/discounts', {
        overview: '',
        add: 'add',
        edit: 'edit/:id',
        remove: 'remove/:id'
    }),

    giftvouchers: include('/giftvouchers', {
        overview: '',
        add: 'add',
        addbulk: 'add-bulk',
        edit: 'edit/:id',
        remove: 'remove/:id'
    }),

    e: include('/e', {
        al: ':path',
        c: 'c/:path',
        g: 'g/:path',
        t: 't/:path',
        tc: 'tc/:path',
        tg: 'tg/:path',
        tgm: 'tgm/:path',
        gc: 'gc/:path',
        s: 's/:path'
    })

    // e: '/e/:type/:path'
    // contactApp: '/e/:type/:path'
}