/**
 * Agenda actions
 */
export const AGENDA_LOGGED_IN = 'AGENDA_LOGGED_IN';
export const AGENDA_LOGGED_OUT = 'AGENDA_LOGGED_OUT';

export function agendaLoggedIn(value) {
    return { type: AGENDA_LOGGED_IN, value };
};

export function agendaLoggedOut() {
    return { type: AGENDA_LOGGED_OUT };
};

export const AGENDA_SET = 'AGENDA_SET';
export const AGENDA_UPDATE = 'AGENDA_UPDATE';
export const AGENDA_UPDATE_SETTINGS = 'AGENDA_UPDATE_SETTINGS';
export const AGENDA_REMOVE = 'AGENDA_REMOVE';

export function agendaSet(name, value) {
    return { type: AGENDA_SET, name, value };
};

export function agendaUpdate(name, value) {
    return { type: AGENDA_UPDATE, name, value };
};

export function agendaUpdateSettings(name, value) {
    return { type: AGENDA_UPDATE_SETTINGS, name, value };
};

export function agendaRemove(name) {
    return { type: AGENDA_REMOVE, name };
};

/**
 * Agenda Access key actions
 */
export const AGENDA_ACCESS_KEY_SET = 'AGENDA_AGENDA_ACCESS_KEY_SET';
export const AGENDA_ACCESS_KEY_REMOVE = 'AGENDA_AGENDA_ACCESS_KEY_REMOVE';

export function agendaAccessKeySet(value) {
    return { type: AGENDA_ACCESS_KEY_SET, value };
};

export function agendaAccessKeyRemove() {
    return { type: AGENDA_ACCESS_KEY_REMOVE };
};