import React from 'react';
import { t } from 'i18next';
import Calendar from 'react-calendar/dist/entry.nostyle';
import moment from 'moment';
import $ from 'jquery';
import routes from '../routing/routes';
import { reverse } from 'named-urls';
import { ApiScheduleService } from '../services/api/schedule.service';

class CalendarSidebar extends React.Component {
    constructor(props) {
        super(props);

        this._isMounted = false;

        this.state = {
            type: 'agenda',
            id: props.agenda ? props.agenda.id : null,
            date: new Date(),
            slotCount: {}
        };
    }

    componentDidMount() {
        this._isMounted = true;
        this.bindCalendar();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        const { date } = this.state;
        this.tileContent = this.tileContent.bind(this);

        return (
            <Calendar
                value={date}
                tileContent={this.tileContent}
                onClickDay={this.onClick}
                showNeighboringMonth={true}
                onActiveDateChange={this.onActiveDateChange}
                onClickMonth={this.onClickMonth}
                showWeekNumbers={this.props.agenda.settings.showWeekNumbers ? this.props.agenda.settings.showWeekNumbers : false}
                prev2Label={false}
                next2Label={false}
                minDetail="year"
            />
        )
    }

    bindCalendar = () => {
        $(document).on('mouseenter', 'button.react-calendar__tile', (e) => {
            $('.react-calendar-tooltip').hide();

            let tooltip = $(e.target).find('.react-calendar-tooltip');
            tooltip.show();
        });

        $(document).on('mouseleave', 'button.react-calendar__tile', () => {
            $('.react-calendar-tooltip').hide();
        });

        document.addEventListener('reloadSmallCalendar', (e) => {
            this.load(moment(e.detail.date));
        });

        document.addEventListener('dateChanged', (e) => {
            this.setState({ date: e.detail.date });

            if (this.calendarRef) {
                let date = moment(e.detail.date);
                this.calendarRef.current.setActiveStartDate(new Date(date.format('YYYY'), date.subtract('1', 'months').format('M')));
            }
        });

        document.addEventListener('caseChanged', (e) => {
            let { id, type } = e.detail;

            if ((this.state.slotCount && this.state.slotCount.length > 0) && this.state.id === id && this.state.type === type) {
                return;
            }

            this.setState({ id: id, type: type });

            this.load(this.state.date, id, type);
        });
    }

    tileContent = ({ date, view }) => {
        if (view !== 'month' || !this.state.slotCount || Object.keys(this.state.slotCount) <= 0) {
            return '';
        }

        date = moment(date).format('YYYY-MM-DD');

        const count = this.state.slotCount[date];

        if (count === undefined) {
            return '';
        }

        let availability = '';
        if (count === 0) {
            availability = 'full';
        } else if (count <= 5) {
            availability = 'almost-full';
        }

        return (
            <React.Fragment>
                <div className="react-calendar-tooltip">{t('common:freeSlots', { count: count })}</div>
                <div className={'react-calendar-availability react-calendar-availability--' + availability}></div>
            </React.Fragment>
        );
    }

    onClick = (value) => {
        let link = '';

        switch (this.state.type) {
            case 'group':
                link = reverse(routes.agenda.group, { date: moment(value).format('YYYY-MM-DD') });
                break;
            case 'agenda':
            default:
                link = reverse(routes.agenda.myagenda, { agenda: this.state.id, date: moment(value).format('YYYY-MM-DD') })
                break;
        }

        this.props.history.push(link);
    }

    onActiveDateChange = ({ activeStartDate }) => {
        this.load(activeStartDate);
    }

    onClickMonth = (value) => {
        this.load(value);
    }

    load = (startDate, id, type) => {
        if (id === undefined) {
            id = this.state.id;
        }

        if (type === undefined) {
            type = this.state.type;
        }

        ApiScheduleService.getFreeSlotCount(
            moment(startDate).startOf('month').startOf('week').format('YYYY-MM-DD'),
            moment(startDate).endOf('month').endOf('week').format('YYYY-MM-DD'),
            id,
            type
        )
            .then((result) => {
                if (!this._isMounted) {
                    return;
                }

                return this.setState({ slotCount: result });
            });
    }
}

export default CalendarSidebar;