import React from 'react';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { t } from 'i18next';
import UncontrolledTooltip from '../reactstrap/UncontrolledTooltip';

const ButtonActionEdit = props => (
    <React.Fragment>
        <Button color="info" size="sm" id={'buttonEdit' + props.i} tag={Link} to={props.to}>
            <i className="fas fa-pencil-alt" aria-hidden></i>
        </Button>
        <UncontrolledTooltip placement="top" target={'buttonEdit' + props.i}>
            {t('common:action-edit')}
        </UncontrolledTooltip>
    </React.Fragment>
)

export default ButtonActionEdit;
