import { LOCATION_UPDATE, LOCATION_REMOVE } from './location.actions';
import { AGENDA_LOGGED_OUT } from '../agenda/agenda.actions';

const locationReducer = (state = null, action) => {
    switch (action.type) {
        case LOCATION_UPDATE:
            return action.value;
        case LOCATION_REMOVE:
        case AGENDA_LOGGED_OUT:
            return null;
        default:
            return state;
    }
}

export default locationReducer;