import React, { Component } from 'react';
import { t } from 'i18next';
import moment from 'moment';

class AutoSuggestItem extends Component {
    constructor(props) {
        super(props);

        this.reset = false;

        this.state = {
            items: props.items
        };

        this.nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, "value").set;
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (this.state.items.length === nextProps.items.length && this.state.items.length === nextState.items.length) {
            return false;
        }
        return true;
    }

    componentDidUpdate() {
        if (!this.reset) {
            this.setState({
                items: this.props.items
            })
        }

        this.reset = false;
    }

    render() {
        const { items } = this.state;

        return (
            items.length > 0 && (
                <ul>
                    {
                        items.map((item, i) => {
                            return this.renderItem(item, i);
                        })
                    }
                </ul>
            )
        );
    }

    renderItem(item, i) {
        return (
            <li key={i} onClick={() => this.handleItemClick(item)}>
                {t('contacts.contact:autosuggest', {
                    firstName: item.firstName,
                    lastName: item.lastName,
                    birthDate: moment(item.birthdate),
                    interpolation: { escapeValue: false }
                })}
            </li>
        );
    }

    handleItemClick = (item) => {
        this.updateContactId(item);

        Object.entries(this.props.fields).forEach(([id, field]) => {
            this.updateDataField(id, field, item);
        });

        this.reset = true;
        this.setState({
            items: {}
        });
    }

    updateContactId = (item) => {
        let contactIdEl = document.getElementById('contactId');
        contactIdEl.setAttribute('data-firstname', item.firstName);
        contactIdEl.setAttribute('data-lastname', item.lastName);

        this.nativeInputValueSetter.call(contactIdEl, item['id'] ? item['id'] : '');

        // contactIdEl.dispatchEvent(new Event('input', { bubbles: true }));
        //contactIdEl.dispatchEvent(new CustomEvent('input', { bubbles: true }));

        if (typeof (Event) === 'function') {
            contactIdEl.dispatchEvent(new Event('input', { bubbles: true }));
        } else {
            let event = document.createEvent('Event');
            event.initEvent('input', true, true);
            contactIdEl.dispatchEvent(event);
        }
    }

    updateDataField = (id, field, item) => {
        let el = document.getElementById(id);

        if (!el) {
            return;
        }

        if (field === 'birthdate' && item[field]) {
            item[field] = moment(item[field]).format('DD/MM/YYYY');
        }

        this.nativeInputValueSetter.call(el, item[field] ? item[field] : '');

        // el.dispatchEvent(new Event('input', { bubbles: true }));

        if (typeof (Event) === 'function') {
            el.dispatchEvent(new Event('input', { bubbles: true }));
        } else {
            let event = document.createEvent('Event');
            event.initEvent('input', true, true);
            el.dispatchEvent(event);
        }
    }
}

export default AutoSuggestItem;