import Heading from './Heading';
import { ButtonAdd, ButtonBack, ButtonIcon, ButtonPrint, ButtonSave } from './buttons';
import { TableHead, TableBody, TableRowLoader, TableRowNoResult } from './table';

export {
    //default
    Heading,

    // buttons
    ButtonAdd,
    ButtonBack,
    ButtonIcon,
    ButtonPrint,
    ButtonSave,

    // Table
    TableHead,
    TableBody,
    TableRowLoader,
    TableRowNoResult
}