/**
 * Client actions
 */
export const CLIENT_LOGGED_IN = 'CLIENT_LOGGED_IN';
export const CLIENT_LOGGED_OUT = 'CLIENT_LOGGED_OUT';

export function clientLoggedIn(value) {
    return { type: CLIENT_LOGGED_IN, value };
};

export function clientLoggedOut() {
    return { type: CLIENT_LOGGED_OUT };
};

export const CLIENT_SET = 'CLIENT_SET';
export const CLIENT_UPDATE = 'CLIENT_UPDATE';
export const CLIENT_UPDATE_SETTINGS = 'CLIENT_UPDATE_SETTINGS';
export const CLIENT_REMOVE = 'CLIENT_REMOVE';

export function clientSet(name, value) {
    return { type: CLIENT_SET, name, value };
};

export function clientUpdate(name, value) {
    return { type: CLIENT_UPDATE, name, value };
};

export function clientUpdateSettings(name, value) {
    return { type: CLIENT_UPDATE_SETTINGS, name, value };
};

export function clientRemove(name) {
    return { type: CLIENT_REMOVE, name };
};

/**
 * Client Access key actions
 */
export const CLIENT_ACCESS_KEY_SET = 'CLIENT_CLIENT_ACCESS_KEY_SET';
export const CLIENT_ACCESS_KEY_REMOVE = 'CLIENT_CLIENT_ACCESS_KEY_REMOVE';

export function clientAccessKeySet(value) {
    return { type: CLIENT_ACCESS_KEY_SET, value };
};

export function clientAccessKeyRemove() {
    return { type: CLIENT_ACCESS_KEY_REMOVE };
};